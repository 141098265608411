<template>
  <v-list-item :to="item.path || item.basePath" :href="item.href" :target="item.href ? '_blank' : '_self'" exact>
    <v-list-item-icon v-if="item.icon">
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ item.title }}</v-list-item-title>
    <v-list-item-action v-if="item.loginRequired && !isLoggedIn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon small v-on="on" style="transform:translateX(6px)">fal fa-lock</v-icon>
        </template>
        <span>Login required</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props, { root }) {
    const isLoggedIn = computed(() => { return root.$store.state.loggedIn })
    const roles = computed(() => { return root.$store.state.roles || {} })

    return {
      isLoggedIn,
      roles
    }
  }
}
</script>
